<template>
  <div>
    <v-alert
      color="cyan"
      border="left"
      elevation="2"
      colored-border
      icon="perm_identity"
    >ชื่อทนาย : {{showLawyerName}}</v-alert>

    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <material-stats-card
          color="green"
          icon="graphic_eq"
          title="จำนวนเคสที่เข้ามาใหม่เดือนล่าสุด"
          :value="caseNumberLatesMonth"
          sub-icon="mdi-calendar"
        />
      </v-col>
      <v-col cols="12" sm="6" lg="4">
        <material-stats-card
          color="orange"
          icon="signal_cellular_alt"
          title="จำนวนเคสคงค้าง (ที่ต้อง Action)"
          :value="openCaseNumber"
          sub-icon="mdi-calendar"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-card-title>กราฟแสดง % Success Rate ย้อนหลัง 1,3,6 และ 12 เดือน</v-card-title>

            <SimpleColumnChart
              :isMock="false"
              :data="successRateBackwards"
              categoryField="name"
              valueField="successRate"
              valueText="Percent (%)"
              :showPercent="true"
            ></SimpleColumnChart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <ChartTwelveMonthCompareOpen></ChartTwelveMonthCompareOpen>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <ChartProportionOpen></ChartProportionOpen>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <render-input :input="{type:'section',text:'Trend'}"></render-input>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <ChartTrendNumberOfCase></ChartTrendNumberOfCase>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-text>
            <ChartTrendCaseAccumulate></ChartTrendCaseAccumulate>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <ChartLawyerTopTen></ChartLawyerTopTen>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import PieChart from "./../../components/amcharts/pie-chart-with-legend";
import SimpleColumnChart from "./../../components/amcharts/simple-column-chart";
import LayeredColumnChart from "./../../components/amcharts/layered-column-chart";

import { toQueryString, toCurrencyFormat } from "./../../js/custom";
import { getNamePrefixString } from "./../../js/custom";
import ChartTrendNumberOfCase from "./chart-trend-number-of-case";
import ChartTrendCaseAccumulate from "./chart-trend-case-accumulate";
import ChartProportionOpen from "./chart-proportion-open";
import ChartTwelveMonthCompareOpen from "./chart-twelve-month-compare-open";
import ChartLawyerTopTen from "./chart-lawyer-top-ten";

export default {
  props: {
    lawyerId: {
      type: String
    },
    lawyerName: {
      type: String
    }
  },
  components: {
    PieChart,
    SimpleColumnChart,
    LayeredColumnChart,
    ChartTrendNumberOfCase,
    ChartTrendCaseAccumulate,
    ChartProportionOpen,
    ChartTwelveMonthCompareOpen,
    ChartLawyerTopTen
  },
  data() {
    return {
      caseNumberLatesMonth: "",
      openCaseNumber: "",
      successRateBackwards: []
    };
  },
  created() {
    this.lawyer_getCaseNumberLatesMonth();
    this.lawyer_getOpenCaseNumber();
    this.lawyer_getSuccessRateBackwards();
  },
  methods: {
    lawyer_getCaseNumberLatesMonth() {
      let url =
        "/dashboard/lawyer/LatestMonthCaseCount?" +
        toQueryString({ lawyerId: this.lawyerId });

      this.$http.get(url).then(res => {
        this.caseNumberLatesMonth = toCurrencyFormat({
          value: res.count,
          decimal: 0
        });
      });
    },
    lawyer_getOpenCaseNumber() {
      let url =
        "/dashboard/lawyer/RemainCaseCount?" +
        toQueryString({ lawyerId: this.lawyerId });

      this.$http.get(url).then(res => {
        this.openCaseNumber = toCurrencyFormat({
          value: res.count,
          decimal: 0
        });
      });
    },
    lawyer_getSuccessRateBackwards() {
      let url =
        "/dashboard/lawyer/SuccessRateCasePrefomanceBackward?" +
        toQueryString({ lawyerId: this.lawyerId });

      this.$http.get(url).then(res => {
        this.successRateBackwards = res.items;
      });
    }
  },
  computed: {
    showLawyerName() {
      if (this.isLawyer) {
        return this.displayName;
      } else {
        return this.lawyerName;
      }
    }
  }
};
</script>


